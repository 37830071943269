@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden;
}

.min-w-72 {
    min-width: 18rem;
}

.height-fit-content {
    height: fit-content;
}

.ski-bg {
    background-image: url("https://www.telegraph.co.uk/content/dam/Travel/ski/serbia-ski-resort.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.football-bg {
    background-image: url("https://ak3.picdn.net/shutterstock/videos/1024336103/thumb/1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.loader {
    border-top-color: #1e7889;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}